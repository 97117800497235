import React from 'react'

import { Link, graphql } from "gatsby"

import Layout from '../components/layout'
import SEO from '../components/seo'


export default function RoutesPage({ data }) {
  const { edges: routes } = data.allMarkdownRemark
  return (
    <Layout>
      <SEO title="Recommended Routes" />
      <h1>Recommended Routes</h1>

      <ul className="routes">
        {routes
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: route }) => {
            return (
              <li className="route-preview" key={route.id}>
                <Link to={route.frontmatter.slug}>{route.frontmatter.title}</Link>
              </li>
            )
          })}
        </ul>
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            slug
          }
        }
      }
    }
  }
`
